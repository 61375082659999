// -----------------------
// Grouped Product Styling
// -----------------------

.grouped-product-wrapper select {
  font-weight: $font-weight__regular;
  font-size: $font-size__l;
  @media only screen and (max-width: $screen__m){
      font-size: $font-size__base;
  }
}

.table.grouped {
    @include lib-table-bordered(
        $_table_type: horizontal
    );

    > thead > tr > th {
        border-bottom: 0;
    }

    .price-box {
        .price {
            font-size: $font-size__l;
            color: $primary__color;
        }
    }

    .control {
        .qty {
            @extend .abs-input-qty;
        }
    }

    .stock.unavailable {
        font-weight: normal;
        margin: 0;
        text-transform: none;
        white-space: nowrap;
    }

    .row-tier-price {
        td {
            border-top: 0;
        }

        .prices-tier {
            margin: 0 -10px 10px;
        }
    }
}
