/* Landing Page Options Widget Styling */
.landingPageOptions-container {
    .hero__wrapper {
        text-align: center;
        section.section__hero {
            background-position: left -65px top 50%;
            /*background-position: left -25px top 50%;*/
            background-repeat: no-repeat;
            background-size: cover;
            display: inline-block;
            position: relative;
            width: 100%;
            max-width: 1425px;
            @media only screen and (max-width: 1130px) {
                height: 300px;
                background-position: center;
            }
            @media only screen and (max-width: 480px) {
                height: 225px;
          }
        }
        .section__hero--wrapper {
            .section__hero--purchase {
                display: inline-block;
                position: relative;
                background-color: #ffffff;
                float: right;
                padding: 2%;
                max-width: 400px;
                text-align: left;
                @media only screen and (max-width: 1130px) {
                    display: block;
                    float: inherit;
                    margin-right: auto;
                    margin-left: auto;
                    margin-top: 250px;
                    width: 85%;
                    text-align: center;
                    max-width: 1000px;
                }
                @media only screen and (max-width: 480px) {
                    width: 90%;
                    margin-top: 180px;
                }
                .section__hero--text {
                    h1 {
                        color: #ed9016;
                    }
                    h2 {
                        margin-top: 1.0rem;
                        margin-bottom: 1.0rem;
                    }
                }
            }
        }
        .section__hero--select {
            h4 {
                margin-bottom: 0.75rem;
            }
            form.zip-form {
                input {
                    display: inline-block;
                    position: relative;
                    width: 49%;
                }
                p.error-msg {
                    display: none;
                }
                button {
                    display: block;
                    width: 49%;
                    text-align: center;
                    margin: 10px 0 10px;
                    @media only screen and (max-width: 1130px){
                        margin-right: auto;
                        margin-left: auto;
                    }
                }
            }
            .section__hero--option {
                form {
                    margin-bottom: 1.5rem;
                    button {
                        width: 100%;
                        span {
                            float: right;
                        }
                    }
                    small.section__hero--linkinfo {
                        display: inline-block;
                    }
                }
            }
        }
        .widget-static-block {
            .section__hero--promo {
                img {
                    height: 25px;
                    float: left;
                    @media only screen and (max-width: 1130px){
                      float: inherit;
                    }
                }
                p {
                    margin-left: 2%;
                    margin-bottom: 2%;
                    display: inline-block;
                    position: relative;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-family: 'Roboto Condensed';
                    font-size: 1.0rem;
                    color: #075534;
                }
                small {
                    display: inline-block;
                    position: relative;
                    width: 100%;
                    @media only screen and (max-width: 1130px){
                        width: 49%;
                        display: block;
                        margin-right: auto;
                        margin-left: auto;
                        text-align: left;
                    }
                    @media only screen and (max-width: 768px) {
                        width: 80%;
                    }
                }
            }
        }
    }
    .howItWorks__wrapper {
        text-align: center;
        margin-top: 90px;
        .section__steps--item {
            display: inline-block;
            position: relative;
            @media only screen and (max-width: 768px) {
                margin-bottom: 20px;
            }
            .widget-static-block {
                p {
                    font-weight: 100;
                    font-size: 0.85rem;
                    width: 80%;
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
    .tabSection__wrapper {
        section.section__tabs {
            .section__tabs--wrapper {
                .section__tabs--tabnav {
                    text-align: center;
                    margin-top:100px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    label.tabnav-label {
                        display: inline-block;
                        position: relative;
                        background-color: #fafafa;
                        border: 1px solid #ededee;
                        border-radius: 0.25rem;
                        font-family: "Raleway", "Helvetica Neue", Verdana, Arial, sans-serif;
                        color: #515356;
                        font-size: 0.65rem;
                        font-weight: 700;
                        text-transform: uppercase;
                        padding: 10px 35px;
                        cursor: pointer;
                        margin: 10px 0 10px 0;
                        min-width: 7.25rem;
                        &:hover {
                            background-color: #075534;
                            box-shadow: 0 0 5px 0 rgba(182, 92, 0, 0.18);
                            color: #fff;
                            font-weight: 400;
                        }
                    }
                    label.tabnav-label.active {
                        background-color: #075534;
                        box-shadow: 0 0 5px 0 rgba(182, 92, 0, 0.18);
                        color: #fff;
                        font-weight: 400;
                    }
                }
                .section__tabs--tabcontainer {
                    margin-top: 100px;
                    @media only screen and (max-width: 768px) {
                        margin-top: 30px;
                    }
                    .section__tabs--tabtrack {
                        display: inline-block;
                        position: relative;
                        min-height: 450px;
                        input.tabs__radio {
                            display: none;
                            opacity: 0;
                            visibility: hidden;
                        }
                        .tabpanel {
                            display: none;
                            .tabpanel__wrapper {
                                display: inline-block;
                                position: relative;
                                .tabpanel__image {
                                    display: inline-block;
                                    position: relative;
                                    float: left;
                                    span {
                                        display: inherit;
                                        position: relative;
                                        text-align: center;
                                        img {
                                            display: inline-block;
                                            position: relative;
                                            max-width: 250px;
                                            vertical-align: bottom;
                                            @media only screen and (max-width: 480px) {
                                                max-width: 200px;
                                            }
                                        }
                                    }
                                }
                                .tabpanel__text {
                                    display: inline-block;
                                    position: relative;
                                    float: right;
                                    @media only screen and (max-width: 768px) {
                                        margin-top: 20px;
                                    }
                                    .turfbuilder--headline {
                                        margin: 0;
                                        width: 90%;
                                        @media only screen and (max-width: 768px) {
                                            display: block;
                                            margin-right: auto;
                                            margin-left: auto;
                                            text-align: center;
                                            margin-bottom: 20px;
                                        }
                                    }
                                    p {
                                        font-weight: 100;
                                        font-size: 0.85rem;
                                        width: 90%;
                                        @media only screen and (max-width: 768px) {
                                            display: block;
                                            margin-right: auto;
                                            margin-left: auto;
                                        }
                                    }
                                    ul {
                                        font-weight: 100;
                                        font-size: 0.85rem;
                                        width: 90%;
                                        padding: 0;
                                        @media only screen and (max-width: 768px) {
                                            display: block;
                                            margin-right: auto;
                                            margin-left: auto;
                                        }
                                        li {
                                            list-style-type: none;
                                            font-family: 'Roboto', sans-serif;
                                            font-weight: 100;
                                            margin-bottom: 0.25rem;
                                            &:before {
                                                content: '\00b7';
                                                width: 10px;
                                                height: 10px;
                                                margin-right: 5px;
                                                font-weight: 400;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .tabpanel.active {
                            display: inline-block;
                            position: relative;
                        }
                    }
                }
                p {
                    display: inline-block;
                    position: relative;
                    width: 100%;
                    margin: 0;
                    @media only screen and (max-width: 768px) {
                        width: 90%;
                        display: block;
                        margin-right: auto;
                        margin-left: auto;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1130px) {
	div.widget.widget-static-block.howItWorks__wrapper {
		margin-top: 22px;
	}
}

.widget.widget-static-block.howItWorks__wrapper{
	 background-color: #f7f7f7;
}
.widget.widget-static-block.howItWorks__wrapper{
	.section__steps--headline{
		padding-top: 10px;
	}
}
.widget.widget-static-block.howItWorks__wrapper{
	padding-top: 0px;/*------ 23-04-2019 -------*/
	margin-top: -5px;/*------ 24-04-2019 -------*/
	.section__steps--wrapper{
		padding-bottom: 57px;
	}
	
}
.landingPageOptions-container{ 
	.howItWorks__wrapper{ 
		.section__steps--item{ 
			.widget-static-block p{
				color: #515356;
				font-weight: normal;
			 }
		 }
	}
}