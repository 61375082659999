//  ---------------------------------------------
//  Scotts Base -  Colors
//  ---------------------------------------------

$scotts-green                                                 : #075534;
$scotts-secondary-green                                       : #138E51;
$scotts-black                                                 : #2A2A2A;
$scotts-gray                                                  : #4d4d4e;
$scotts-light-gray                                            : #959393;
$scotts-white                                                 : #f3f3f3;
$scotts-accent-orange                                         : #fcaf19;

// Additional Colors
$color_grey_shade                                             : #64686e;
$color_dark30_green_shade                                     : #4d4e4e;
$color_black15_shade                                          : #262626;
$color_black20_shade                                          : #333333;
$color_lightyellow_shade                                      : #ffc;

