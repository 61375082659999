//Scss file for the SMG SpecList Widget

.specList-container {
    display: inline-block;
    width: 100%;
}

.page-wrapper .page-main .grid > {
    h2.specListHeader {
        display: inline-block;
        position: relative;
        margin: 0;
        float: left;
        @media only screen and (max-width: $screen__m) {
            text-align: center;
        }
    }
    ul.specListDetails {
        list-style: none;
        display: inline-block;
        position: relative;
        padding: 0;
        margin-bottom: 0;
        float: right;
        li.specListDetailCell {
            display: inline-block;
            @media only screen and (max-width: $screen__m) {
                padding-left: 2%;
            }
        }
    }
}



