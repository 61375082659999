//  ---------------------------------------------
//  Scotts Base - Typography
//  ---------------------------------------------

// Custom Line heights
$line-height__m                                               : 1.4 !default;


// Cart Styles
$cart-half-gutter-width                                       : 13px;
$cart-gutter-width                                            : $cart-half-gutter-width * 2;
$cart-padding__base                                           : 16px;
$cart-padding_xs                                              : $cart-padding__base / 4;
$cart-padding_s                                               : $cart-padding__base / 2;
$cart-padding_m                                               : $cart-padding__base;
$cart-padding_l                                               : $cart-padding__base * 1.25;
$cart-padding_xl                                              : $cart-padding__base * 2;


// Borders
$border-width__thick                                           : 2px !default;

// Checkout H2 Styles
$cart-checkout-h2-border                                      : 2px solid $checkout-accent-color !default;
$cart-checkout-h2-padding                                     : 0 0 20px 0 !default;
$cart-checkout-h2-letter-spacing                              : 2px !default;
$cart-checkout-h2-letter-spacing-mobile                       : 1.7px !default;


$lato: 'Lato', sans-serif;
$opensans: 'Open Sans', sans-serif;







