/* Widget Start - Location - App / design / frontend / Scottsbase / default / SMG_ProdcutCallout / styles */

.page-wrapper {
    .page-main {
        section.product-grid {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            align-items: flex-start;
            padding-bottom: 24px;
            position: relative;
            .shop-cta {
                margin: 50px 0 0 0;
                .button {
                  margin-bottom: 20px;
                  padding: 15px 45px;
                  color: #fff;
                  background: #008065;
                  line-height: 0;
                  border-radius: 4px;
                  letter-spacing: .5px;
                  font-family: "Hellix-Heavy", Lucida Grande, Arial, Helvetica, sans-serif;
                }
              .button:hover{
                  background: #00634F;
                  transition: background .2s linear;
              }
            }
            .item {
                background-size: cover;
                text-align: center;
                max-width: 32%;
                padding: 35px 40px;
                margin: 25px 0 0 0;
                box-sizing: border-box;
                position: relative;
                @include max-screen($screen__l) {
                    margin: 0 16px 16px;
                    max-width: 90%;
                    img {
                        max-height: 320px;
                    }
                }
                h2 {
                    font-size: 40px;
                    margin: 0 -20px 15px;
                    @include max-screen($screen__l) {
                        font-size: 35px;
                    }
                }
                a.prodGrid-imgLink {
                    img {
                        max-height: 400px;
                    }
                }
            }
        }
    }
}





