/* Widget Start - Location - App / design / frontend / Scottsbase / default / SMG_ProdcutCallout / styles */
.page-wrapper .page-main .column.main section.hero-callout {
    height: 600px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    @include max-screen($screen__l) {
        display: none;
    }
    .heroCallout-bannerText {
        margin: 0 0 0.8rem 0;
    }
    .heroCallout-bannerTextHighlight {
        margin: 0 0 0.8rem 0;
    }
    .heroCallout-desktopContent {
        max-width: 500px;
        padding-left: 50px;
        position: relative;
        display: inline-block;
        margin: 4% 0 18% 0;
        h2 {
            margin-bottom: 25px;
            width: 75%;
        }
        .heroCallout-bannerText {
            width: 85%;
        }
        .heroCallout-bannerTextHighlight {
            width: 85%;
        }
    }
    @media screen and (max-width: 1399px) and (min-width: 1024px) {
        .heroCallout-desktopContent {
            width: 34%;
            padding-left: 40px;
        }
    }
}

.heroCallout-mobileContent {
    @media only screen and (min-width: $screen__l) {
        display: none;
    }
    @media only screen and (max-width: $screen__l) {
        display: inline-block;
        position: relative;
    }
    h2 {
        width: 90%;
        margin: 0 0 1.0rem 0;
        font-size: 32px;
    }
    .heroCallout-bannerText {
        width: 90%;
        margin: 0 0 0.8rem 0;
    }
    .heroCallout-bannerTextHighlight {
        width: 90%;
        margin: 0 0 0.8rem 0;
    }
}

