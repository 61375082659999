/*  SECTIONS  */
.section {
  clear: both;
  padding: 0px;
  margin: 0px;
}

/*  COLUMN SETUP  */
.col {
  display: block;
  float:left;
  margin: 1% 0 1% 1.6%;
}
.col:first-child { margin-left: 0; }
.col:last-child { margin-left: 0; }

/*  GROUPING  */
.group:before,
.group:after { content:""; display:table; }
.group:after { clear:both;}
.group { zoom:1; /* For IE 6/7 */ }

/*  GRID OF TWELVE  */
.span_12_of_12 {
  width: 100%;
}

.span_11_of_12 {
  width: 91.53%;
}
.span_10_of_12 {
  width: 83.06%;
}

.span_9_of_12 {
  width: 74.6%;
}

.span_8_of_12 {
  width: 66.13%;
}

.span_7_of_12 {
  width: 57.66%;
}

.span_6_of_12 {
  width: 49.2%;
}

.span_5_of_12 {
  width: 40.73%;
}

.span_4_of_12 {
  width: 32.26%;
}

.span_3_of_12 {
  width: 23.8%;
}

.span_2_of_12 {
  width: 15.33%;
}

.span_1_of_12 {
  width: 6.866%;
}

/*  GO FULL WIDTH BELOW 768 PIXELS */
@media only screen and (max-width: 768px) {
  .col {  margin: 1% 0 1% 0%; }

  .span_1_of_12, .span_2_of_12, .span_3_of_12, .span_4_of_12, .span_5_of_12, .span_6_of_12, .span_7_of_12, .span_8_of_12, .span_9_of_12, .span_10_of_12, .span_11_of_12, .span_12_of_12 {
    width: 100%;
  }
}