//
//  Variables
//  _____________________________________________

$checkout-payment-option-title__border            : $checkout-payment-method-title__border !default;
$checkout-payment-option-title__color             : $link__color !default;
$checkout-payment-option-title__padding           : $checkout-payment-method-title__padding !default;
$checkout-payment-option-title-mobile__padding    : $checkout-payment-method-title-mobile__padding !default;

$checkout-payment-option-title-icon__font-size    : 32px !default;
$checkout-payment-option-title-icon__line-height  : 16px !default;
$checkout-payment-option-title-icon__margin       : 0 !default;
$checkout-payment-option-title-icon__color        : $minicart-icons-color !default;
$checkout-payment-option-title-icon__hover__color : $primary__color !default;

$checkout-payment-option-content__padding__xl     : $checkout-payment-method-content__padding__xl !default;

.checkout-payment-method {
    .payment-option {
        margin: 25px 0 0 0;
        &._collapsible {
            .payment-option-title {
                cursor: pointer;
                height: auto !important; //overriding base form height that's needed
                .action-toggle {
                    width: 100%;
                    &:after {
                        @include discount-icon-open();
                        content: '';
                        float: right;
                    }
                }
            }
            .payment-option-content {
                display: none;
                .form-discount {
                    margin: 45px 0 0 0;
                    .payment-option-inner{
                        input.input-text.mage-error{
                            border-bottom: 1px solid $checkout-alert-red; 
                        }
                    }
                    .actions-toolbar {
                        float: right;
                        button {
                            @include cart-checkout-button-sm();
                            margin: 0;
                            &[disabled]{
                                @include cart-checkout-button-disabled-sm();
                            }
                        }
                    }
                }
                
            }
        }
        &._active {
            .payment-option-title {
                .action-toggle {
                    &:after {
                        @include discount-icon-close();
                    }
                }
            }
        }
    }

    .payment-option-title {
        @include lib-css(border-top, $checkout-payment-option-title__border);
        .action-toggle {
            cursor: pointer;
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size         : $checkout-payment-option-title-icon__font-size,
                $_icon-font-line-height  : $checkout-payment-option-title-icon__line-height,
                $_icon-font-color        : $checkout-payment-option-title-icon__color,
                $_icon-font-color-hover  : $checkout-payment-option-title-icon__hover__color,
                $_icon-font-color-active : $checkout-payment-option-title-icon__color,
                $_icon-font-margin       : $checkout-payment-option-title-icon__margin,
                $_icon-font-position     : after
            );
        }
    }

    .payment-option-inner {
        float: left;
        width: 80%;
        margin: 12px 0 0 0;
        @include max-screen($screen__l) {
            width: 75%;
        }
        @include max-screen($screen__m) {
            width: 85%;
        }
        @include max-screen($screen__s) {
            width: 80%;
        }
        @include max-screen($screen__xs) {
            width: 70%;
        }
        @include max-screen($screen__xxs) {
            width: 65%;
        }
        .field {
            .control {
                width: 100%;
            }
        }
    }

    .credit-card-types {
        padding: 0;

        .item {
            display: inline-block;
            list-style: none;
            margin: 0 $indent__xs 0 0;
            vertical-align: top;

            &._active {
                font-weight: $font-weight__bold;

                img {
                    filter: grayscale(0%);
                }
            }

            &._inactive {
                opacity: 0.4;
            }

            span {
                display: inline-block;
                padding-top: 6px;
                vertical-align: top;
            }
        }

        img {
            filter: grayscale(100%); // For Webkit browsers
            transition: all 0.6s ease; // Fade to color for Chrome and Safari
        }
    }

    .ccard {
        .legend {
            @extend .abs-visually-hidden;
        }

        .fields {
            > .year {
                padding-left: $indent__xs;
            }

            .select {
                padding-left: $indent__xs;
                padding-right: $indent__xs;
            }
        }

        .month {
            .select {
                width: 140px;
            }
        }

        .year {
            .select {
                width: 80px;
            }
        }

        .number {
            .input-text {
                width: 225px;
            }
        }

        > .field.cvv {
            > .control {
                padding-right: $indent__base;
                width: auto;
            }
        }

        .cvv {
            .input-text {
                width: 55px;
            }
        }

        &.fieldset {
            > .field {
                .fields.group.group-2 {
                    .field {
                        width: auto !important;
                    }
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .checkout-payment-method {
        .payment-option {
            @include lib-css(margin, 0 -($checkout-payment-option-title-mobile__padding));

            .payment-option-title {
                margin: 15px !important;
            }

            .payment-option-content {
                @include lib-css(padding, 0 $checkout-payment-option-title-mobile__padding $indent__base);
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-payment-method {
        .payment-option-content {
            .payment-option-inner {
                + .actions-toolbar {
                    margin-left: 0;
                }
            }
        }
    }
}
