//  ---------------------------------------------
//  Typography
//  ---------------------------------------------

// Import scottsbasevars
@import 'source/scottsbasevars';

//  Colors
$primary__color                                               : $scotts-green;
$secondary__color                                             : $scotts-secondary-green;
$active__color                                                : $scotts-green;
$text__color                                                  : $scotts-black; //sets body color


//  Links
$link__color                                                  : $primary__color;
$link__text-decoration                                        : none;

$link__visited__color                                         : $primary__color;
$link__visited__text-decoration                               : none;

$link__hover__color                                           : $secondary__color;
$link__hover__text-decoration                                 : underline;

$link__active__color                                          : $primary__color;
$link__active__text-decoration                                : underline;


//  Sizes
$font-size__base                                              : $font-size-unit-ratio * $font-size-ratio__base * 1px; // 14px - Will display in px
$font-size__xl                                                : ceil(1.5 * $font-size__base); // 21px
$font-size__l                                                 : ceil(1.25 * $font-size__base); // 18px
$font-size__s                                                 : ceil(0.85 * $font-size__base); // 12px
$font-size__xs                                                : floor(0.75 * $font-size__base); // 11px


//  Fonts
$font-family-name__base                                       : 'Lato';
$font-family__sans-serif                                      : 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$font-family__base                                            : $font-family-name__base, $font-family__sans-serif; // Default body font


//  Headings
$h1__margin-bottom__desktop                                   : $indent__xl !default;
$h1__font-size                                                : $font-size__xl;
$h1__font-color                                               : $primary__color;
$h1__font-family                                              : $font-family__secondary;
$h1__font-weight                                              : $font-weight__bold;

$h2__font-size                                                : $font-size__xxl;
$h2__font-color                                               : $text__color;
$h2__font-family                                              : $font-family__secondary;

$h3__font-size                                                : $font-size__base;
$h3__font-color                                               : $text__color;
$h3__font-family                                              : $font-family__secondary;

$h5__font-size                                                : $font-size__l;
$h5__font-color                                               : $primary__color;
$h5__font-family                                              : $font-family__secondary;






//  ---------------------------------------------
//  Button
//  ---------------------------------------------
$button__shadow                                               : inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;





//  ---------------------------------------------
//  Layout grid
//  ---------------------------------------------

//  Header
$header-icons-color                                           : $color-gray56 !default;
$header-icons-color-hover                                     : $color-gray20 !default;





//  ---------------------------------------------
//  Icons
//  ---------------------------------------------

$icon-edit                                                    : '\e606' !default;






//  ---------------------------------------------
//  Popups
//  ---------------------------------------------

//  Checkout tooltip
$checkout-tooltip-icon-arrow__font-size                       : 10px !default;
$checkout-tooltip-icon__font-size                             : 21px !default;

$checkout-tooltip-content-mobile__right                       : -($indent__s) !default;
$checkout-tooltip-content-mobile__top                         : 30px + $checkout-tooltip-icon-arrow__font-size !default;
$checkout-tooltip-content__background-color                   : $color-gray-light01 !default;
$checkout-tooltip-content__padding                            : 12px !default;
$checkout-tooltip-content__border-width                       : 1px !default;

$checkout-tooltip-content__border-color                       : $color-gray60 !default;
$checkout-tooltip-content__font-size                          : $font-size__base !default;
$checkout-tooltip-content__width                              : 270px !default;
$checkout-tooltip-content__active__border-color               : darken($checkout-tooltip-content__border-color, 20%) !default;
$checkout-tooltip-icon-arrow__left                            : -( $checkout-tooltip-content__padding + $checkout-tooltip-icon-arrow__font-size - $checkout-tooltip-content__border-width) !default;

$checkout-billing-address-details__line-height                : 27px !default;


// Checkout
$checkout-step-title__padding                                 : $indent__s !default;
$checkout-step-title__border                                  : $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size                               : 26px !default;
$checkout-step-title__font-weight                             : $font-weight__light !default;
$checkout-sidebar__columns                                    : 4 !default;
$checkout-shipping-address__max-width                         : 500px !default;





//  ---------------------------------------------
//  Sidebar
//  ---------------------------------------------

// Used in cart sidebar, Checkout sidebar, Tier Prices, My account navigation, Rating block background
$sidebar__background-color                                    : $color-white-smoke !default;





//  ---------------------------------------------
//  Variables missing in extend.scss
//  ---------------------------------------------

$addto-color                                                  : $primary__color !default;
$addto-hover-color                                            : $link__hover__color !default;






//  ---------------------------------------------
//  Product
//  ---------------------------------------------

$product-name-link__color                                     : $text__color !default;
$product-name-link__color__active                             : $text__color !default;
$product-name-link__color__hover                              : $text__color !default;
$product-name-link__color__visited                            : $text__color !default;

$product-name-link__text-decoration                           : none !default;
$product-name-link__text-decoration__active                   : $link__hover__text-decoration !default;
$product-name-link__text-decoration__hover                    : $link__hover__text-decoration !default;
$product-name-link__text-decoration__visited                  : $link__hover__text-decoration !default;





//  ---------------------------------------------
//  Minicart
//  ---------------------------------------------

$minicart-icons-color                                         : $header-icons-color !default;
$minicart-icons-color-hover                                   : $header-icons-color-hover !default;
