/* Widget Start - Location - App / design / frontend / Scottsbase / default / SMG_ProdcutCallout / styles */

.product-callout-widget.product-info-container {
    display: inline-block;
    position: relative;
    margin: 0 0 100px 0 !important;
    @media only screen and (max-width: $screen__l) {
      margin: 0 0 85px 0 !important;
    }
    .product-info {
        .productCallout-imageContainer {
            display: inline-block;
            position: relative;
            text-align: center;
            img {
                width: 80%;
                max-width: inherit;
                @media only screen and (max-width: 1200px) {
                    width: 90%;
                }
                @media only screen and (max-width: $screen__l) {
                    width: 55%;
                }
                @media only screen and (max-width: $screen__s) {
                    width: 65%;
                }
            }
        }
        @media only screen and (max-width: $screen__l) {
            display: block;
        }
        .productCallout-contentContainer {
            @media only screen and (max-width: $screen__s) {
                float: left;
                width: 98%;
            }
            h2 {
                text-align: left;
                width: 70%;
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 1.25rem;
                @media only screen and (max-width: $screen__l) {
                    width: 80%;
                    padding: 0;
                    font-size: 32px;
                    line-height: 32px;
                    margin-bottom: 15px;
                    margin-top: 15px;
                }
                @media only screen and (max-width: $screen__m) {
                    width: 95%;
                    margin-right: auto;
                    margin-left: auto;
                    text-align: center;
                }
            }
            p {
                text-align: left;
                width: 70%;
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 1.5rem;
                @media only screen and (max-width: $screen__l) {
                    padding: 0;
                    width: 80%;
                }
                @media only screen and (max-width: $screen__m) {
                    width: 95%;
                    margin-right: auto;
                    margin-left: auto;
                }
            }
            .selected-bannerTextImage {
                display: block;
                position: relative;
                width: 70%;
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 1.0rem;
                @media only screen and (max-width: $screen__l) {
                    width: 80%;
                    margin-bottom: 0.5rem;
                    text-align: center;
                }
                img {
                    width: 30%;
                    @media only screen and (max-width: $screen__l) {
                        width: 25%;
                    }
                    @media only screen and (max-width: $screen__m) {
                        width: 30%;
                    }
                }
            }
        }
    }
}

.product-callout-widget.product-info-container {
    .product-info {
        .productCallout-contentContainer.text-left {
            float: left;
            h2 {
                margin-right: auto;
                margin-left: auto;
            }
            p {
                margin-right: auto;
                margin-left: auto;
            }
        }
        .productCallout-contentContainer.text-right {
            float: right;
        }
    }
}


