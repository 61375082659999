//
//  Shopping cart
//  ---------------------------------------------

//  Summary block

.checkout-cart-index {

    h1, h2, h3, h4, h5, h6 {
        text-transform: uppercase;
        letter-spacing: .2rem; // 2px
    }

    .page-header {
        .header.content {
            .nav-toggle {
                @include max-screen($screen__l) {
                    display: none;
                }
            }
            .minicart-wrapper {
                display: none;
            }
            .nav-sections {
                display: none !important;
            }
        }
    }

    .page-main {
        .columns {
            .column {
                .actions {
                    padding-left: 15px;
                    .action.continue {
                        font-size: 1.6rem;
                        &:before {
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            font-size: 20px;
                            line-height: 20px;
                            font-family: "Font Awesome\ 5 Free";
                            content: "\f0d9";
                            font-weight: 900;
                            margin: 0;
                            vertical-align: top;
                            display: inline-block;
                            overflow: hidden;
                            speak: none;
                            text-align: center;
                        }
                    }
                }
                .page-title-wrapper {
                    display: block;
                    padding-left: 15px;
                    h1 {
                        @include cart-checkout-h2-font($border: 0);
                    }
                }
                .cart-container {
                    @include cart-checkout-body-font();
                    @include max-screen($screen__m) {
                        @include cart-checkout-body-font-mobile();
                    }
                    line-height: 21px;
                    padding-left: 15px;
                    padding-right: 15px;
                    position: relative;

                    .custom-checkout-btn-wrap.stickyCart{
                        @include max-screen($screen__m) {
                            position: fixed;
                            top: 90px;
                            right: 0;
                            width: 100%;
                            padding: 0;
                            z-index: 9999;
                            display: block;
                        }
                    }
                    .form-cart {
                        @include max-screen($screen__m) {
                            @extend .abs-shopping-cart-items-mobile;
                        }
                        .cart.table-wrapper {
                            margin-bottom: 32px;

                            #shopping-cart-table {
                                td {
                                    @include max-screen($screen__m) {
                                        width: 100%;
                                        text-align: left;
                                        display: inline-block;
                                    }
                                }
                                .col {
                                    width: 15.5%;
                                    margin: 0;
                                    padding: $cart-padding_xl $cart-gutter-width $cart-padding_xl 0;
                                    text-align: left;
                                    display: table-cell;
                                    @include max-screen($screen__m) {
                                        width: 15%;
                                    }
                                    @include max-screen($screen__s) {
                                        padding: $cart-padding_l $cart-gutter-width $cart-padding_l 0;

                                        &.qty,
                                        &.price,
                                        &.subtotal,
                                        &.msrp {
                                            box-sizing: border-box;
                                            display: block;
                                            float: left;
                                            text-align: center;
                                            white-space: nowrap;
                                            width: 33%;

                                            &:before {
                                                content: attr(data-th) ':';
                                                display: block;
                                                font-weight: $font-weight__bold;
                                                padding-bottom: $indent__s;
                                            }
                                        }
                                    }
                                }
                                .col.item {
                                    width: 36%;
                                    @include max-screen($screen__l) {
                                        width: 34%;
                                    }

                                    @include max-screen($screen__s) {
                                        width: 100%;
                                        padding-right: 0;
                                        padding-bottom: 0;
                                    }
                                    .remove-product-mobile {
                                        display: none;
                                        @include max-screen($screen__s) {
                                            display: block;
                                            float: right;
                                            width: 2%;
                                        }
                                        .actions-toolbar {
                                            margin: 0;
                                            .cart-remove-icon {
                                                display: block;
                                                content: '';
                                                float: right;
                                                @include cart-remove-icon();
                                            }
                                        }
                                    }
                                    
                                }
                                .col.price {
                                    @include max-screen($screen__s) {
                                        display: none;
                                    }
                                }
                                .col.qty {
                                    @include max-screen($screen__s) {
                                        width: 50%;
                                        text-align: left;
                                        display: inline-block;
                                        padding-top: 0;
                                    }
                                }
                                .col.subtotal {
                                    @include max-screen($screen__s) {
                                        width: 50%;
                                        text-align: right;
                                        padding-top: 0;

                                        &:before {
                                            display: none;
                                        }
                                    }
                                }

                                .col.remove {
                                    padding: 32px 0 0 0;
                                    float: right;
                                    width: 2%;
                                    @include max-screen($screen__s) {
                                        display: none;
                                    }
                                    .actions-toolbar {
                                        margin: 0;
                                        .cart-remove-icon {
                                            display: block;
                                            content: '';
                                            float: right;
                                            @include cart-remove-icon();
                                        }
                                    }
                                    
                                }
                                .col.msrp {
                                    @include max-screen($screen__s) {
                                        white-space: normal;
                                    }
                                }
                                .col.action {
                                    @include max-screen($screen__l) {
                                        width: 10%;
                                        color: purple !important;
                                        background: lime !important;
                                    }
                                }
                                thead {
                                    @include max-screen($screen__s) {
                                        display: none;
                                    }

                                    tr {
                                        .col {
                                            padding-top: 0;
                                            padding-bottom: $cart-padding_m;
                                            text-transform: uppercase;
                                            font-size: $font-size__s;
                                            font-weight: bold;

                                            @include max-screen($screen__s) {
                                                &:not(.item) {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                                .cart.item {

                                    @include max-screen($screen__s) {
                                        border-top: 0;
                                        display: flex;
                                    }

                                    .item-info {
                                        .item {
                                            font-size: $font-size__m;
                                            line-height: $line-height__s;

                                            @include max-screen($screen__m) {
                                                font-size: $font-size__ms;
                                                line-height: $line-height__l;
                                            }

                                            .product-item-photo {
                                                width: calc(49% - #{$cart-half-gutter-width});
                                                padding-right: $cart-gutter-width;
                                                display: inline-block;

                                                @include max-screen($screen__s) {
                                                    position: static;
                                                    text-align: center;
                                                    width: 26%;
                                                    padding: 0 $cart-padding_m 0 0;
                                                    display: block;
                                                    float: left;
                                                    margin-bottom: 0;
                                                }
                                            }
                                            .product-item-details {
                                                width: calc(49% - #{$cart-half-gutter-width});
                                                padding-right: 0;
                                                display: inline-block;

                                                @include max-screen($screen__s) {
                                                    width: 50%;     
                                                    display: inline-block;
                                                }

                                                .product-item-name {
                                                    margin: 0;
                                                    a {
                                                        color: $scotts-black;
                                                        font-weight: normal;
                                                    }
                                                }
                                                .item-options {
                                                    dd {
                                                        margin: 0;
                                                    }
                                                    .simple-img-wrapper {
                                                        .cust-simple-image {
                                                            display: none;
                                                        }
                                                        .options-cont-wrp {
                                                            font-size: $font-size__ms;
                                                            line-height: $line-height__m;

                                                            @include max-screen($screen__s) {
                                                                font-size: $font-size__s;
                                                                line-height: $line-height__l;
                                                            }
                                                            dd {
                                                                margin-bottom: 1.2rem;
                                                            }
                                                            dd,
                                                            strong {
                                                                font-weight: normal;
                                                            }
                                                            dt {
                                                                margin-right: 0.25rem;
                                                            }

                                                            @include max-screen($screen__s) {
                                                                dt {
                                                                    clear: none;
                                                                }
                                                                dd {
                                                                    float: none;
                                                                }
                                                            }
                                                            &:after {
                                                                display: block;
                                                            }
                                                        }
                                                    }

                                                }
                                            }
                                        }
                                        .qty {
                                            &:before {
                                                @include max-screen($screen__s) {
                                                    content: attr(data-th);
                                                    text-transform: uppercase;
                                                }
                                            }
                                            .field.qty {
                                                @include max-screen($screen__s) {
                                                    display: inline-block;
                                                }
                                                .control.qty {
                                                    display: flex;
                                                    .qty {
                                                        @include cart-quantity-number();
                                                    }
                                                }
                                            }
                                            .action.update {
                                                font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
                                                text-decoration: underline;
                                                text-align: left;
                                                color: $checkout-accent-color;
                                                font-size: 1.2rem;
                                                margin-left: 0;
                                                margin-top: 10px;
                                                margin-bottom: 0;
                                                padding-left: 0;
                                                cursor: pointer;
                                                &:before {
                                                    display: none;
                                                }
                                            }
                                            .actions-toolbar {
                                                margin-top: 0!important;
                                                a.action-delete {
                                                    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
                                                    text-decoration: underline;
                                                    font-size: 1.2rem;
                                                    color: $checkout-accent-color;
                                                }
                                            }
                                        }
                                        .price-including-tax,
                                        .price-excluding-tax {
                                            font-size: 16px;
                                            color: $scotts-black;

                                            .price {
                                                font-weight: normal;
                                            }

                                            @include max-screen($screen__s) {
                                                font-size: $font-size__ms;
                                                display: inline-block;
                                                position: absolute;
                                                bottom: 20px;
                                                right: 0;

                                                .price {
                                                    display: block;
                                                }
                                            }
                                        }
                                        &:last-child {
                                            border:0;
                                        }
                                    }

                                    .item-actions {
                                        td {
                                            padding: 0;
                                            .actions-toolbar > .action {
                                                display: none;
                                                visibility: hidden;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .actions-toolbar > .action.action-edit  {
                            display: none;
                        }

                        .cart.main.actions {
                            display: none;
                        }
                    }
                    .cart-parent-summary {

                        .cart-summary-divlink {
                            width: 40%;
                            float: left;
                            position: relative;
                            color: $scotts-light-gray;
                            @include max-screen($screen__l) {
                                padding: 1px 0;
                            }
                            @include max-screen($screen__s) {
                                width: 100%;
                            }

                            .block.discount {
                                h1.title {
                                    @include cart-checkout-h2-font();
                                    cursor: pointer;
                                    &:after {
                                        @include discount-icon-open();
                                        content: '';
                                        overflow: hidden;
                                        float: right;
                                        position: relative;
                                    }
                                }
                                .content {
                                    display: block !important;
                                }
                                .content.disc_active {
                                    display: none !important;
                                }
                                &.active {
                                    .title:after {
                                        @include discount-icon-close();
                                    }
                                }
                            }
                            #discount-coupon-form {
                                margin: 45px 0 0 0;
                                .fieldset.coupon {
                                    .field {
                                        float: left;
                                        width: 80%;
                                        margin: 12px 0 0 0;
                                        @include max-screen($screen__l) {
                                            width: 70%;
                                        }
                                        @include max-screen($screen__m) {
                                            width: 65%;
                                        }
                                        @include max-screen($screen__s) {
                                            width: 80%;
                                        }
                                        @include max-screen($screen__xs) {
                                            width: 70%;
                                        }
                                        @include max-screen($screen__xxs) {
                                            width: 65%;
                                        }
                                        .label {
                                            display: none;
                                        }
                                    }
                                }
                                .fieldset.coupon > .field:not(.choice) > .control {
                                    width: 100%;
                                    @include max-screen($screen__m) {
                                        width: 100%;
                                    }
                                    @include max-screen($screen__xs) {
                                        clear: left;
                                    }
                                }
                            }
                        }
                        .cart-summary {
                            #block-shipping.custshipblk {
                                #block-summary {
                                    display: block !important;
                                    padding: 0 25px;
                                }
                            }
                            #ac-wrapper {
                                position: fixed;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background-color: rgba(0,0,0,0.5);
                                z-index: 1001;
                                #popup {
                                    width: 487px;
                                    height: auto;
                                    background: $color-white;
                                    box-shadow: $color_grey_shade 0 0 3px 3px;
                                    -moz-box-shadow: $color_grey_shade 0 0 3px 3px;
                                    -webkit-box-shadow: $color_grey_shade 0 0 3px 3px;
                                    position: relative;
                                    top: 50%;
                                    left: 50%;
                                    @include translate(-50%, -50%);
                                    span.closes {
                                        cursor: pointer;
                                        position: relative;
                                        right: 10px;
                                        display: inline-block;
                                        width: 100%;
                                        text-align: right;
                                    }
                                }
                            }
                        }
                    }
                    #gift-options-cart {
                        .cart-gift-item {
                            .gift-item-block {
                                .title {
                                    font-family: $lato;
                                    font-weight: 400;
                                    color: $scotts-black;
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #maincontent {
        .columns .column {
            .cart-container {
                form#form-validate {
                    border: 0;
                    width: 100%;
                }
            }
        }
    }

    div[name="shippingAddress.region_id"],
    div[name="shippingAddress.country_id"] {
        display: none !important;
    }
    #popup div[name="shippingAddress.postcode"] {
        .input-text {
            height: 30px;
            width: 200px !important;
        }
    }
    .field-tooltip {
        .field-tooltip-action:before {
            opacity: 1;
            color: $checkout-green;
        }
    }
    .qtyminus,
    .qtyplus {
        @include cart-quantity-controls();
    }
    .pop-cust-click.title {
        color: $checkout-green;
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 0.8rem;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 5px;
        &:hover {
            transition: background 0.2s;
            color: $color_black;
        }
    }

}

.cart-summary {
    @extend .abs-add-box-sizing;
    @extend .abs-adjustment-incl-excl-tax;

    min-width: 400px;
    margin-bottom: $indent__m;
    padding: 0;
    float: right;
    position: relative;

    @include max-screen($screen__l) {
        min-width: 350px;
    }

    @include max-screen($screen__m) {
        min-width: 300px;
    }

    @include max-screen($screen__s) {
        min-width: auto;
        width: 100%;
    }

    > .title {
        display: block;
        @include lib-heading(h3);
    }

    .actions-toolbar {
        .column.main & {
            @extend .abs-reset-left-margin-desktop;
            > .secondary {
                float: none;
            }
        }
    }

    .block {
        @extend .abs-discount-block;
        margin-bottom: 0;

        .item-options {
            margin-left: 0;
        }

        .fieldset {
            margin: 15px 0 $indent__m $indent__xs;

            .field {
                @include lib-form-field-type-revert($_type: block);
                margin: 0 0 $indent__s;

                &.note {
                    font-size: $font-size__s;
                }
            }

            .methods {
                .field {
                    > .label {
                        display: inline;
                    }
                }
            }
        }

        .fieldset.estimate {
            > .legend,
            > .legend + br {
                @extend .abs-no-display;
            }
        }
    }
}

//  Totals block
.cart-totals {
    @extend .abs-sidebar-totals;
    background-color: $color-gray-light01;
    padding: 15px;

    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;
    }

    .display.coupon {
        display: none;
    }
}

//  Products table
.cart.table-wrapper {

    @include max-screen($screen__s) {
        overflow: inherit;
    }

    .items { // Google Chrome version 44.0.2403.107 m fix
        min-width: 100%;
        width: auto;
    }

    .items {
        thead + .item {
            border-top: $border-width__thick solid $checkout-accent-color;
        }

        > .item {
            border-bottom: $border-width__base solid $border-color__base;
            position: relative;
        }
    }

    .item-actions {
        .actions-toolbar {
            @extend .abs-reset-left-margin-desktop;
            text-align: left;
        }
    }

    .col {
        padding-top: $indent__base;

        &.qty {
            .input-text {
                @extend .abs-input-qty;
            }

            .label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .item {
        &-actions td {
            padding-bottom: $indent__s;
            text-align: center;
            white-space: normal;
            text-align: right;
        }

        .col {
            &.item {
                display: block;
                min-height: 75px;
                padding: $indent__m 0 $indent__s 75px;
                padding: 27px 8px $indent__s;
                position: relative;
            }
        }
    }

    .action {
        &.help.map {
            @extend .abs-action-button-as-link;
            font-weight: $font-weight__regular;
        }
    }

    .action {
        margin-bottom: $indent__s;
    }

    .product {
        &-item-photo {
            left: 0;
            padding: 0;
            top: 15px;
            display: table-cell;
            max-width: 100%;
            padding-right: $indent__base;
            position: static;
            vertical-align: top;
            width: 1%;
        }

        &-item-details {
            display: table-cell;
            vertical-align: top;
            white-space: normal;
            width: 99%;
        }

        &-item-name {
            display: inline-block;
            font-weight: $font-weight__regular;
            margin-top: -6px;
        }
    }

    .gift-registry-name-label {
        &:after {
            content: ':';
        }
    }

    //  Product options
    .item-options {
        @extend .abs-product-options-list;
        @extend .abs-add-clearfix;
        font-size: $font-size__s;
        margin-bottom: $indent__s;
    }

    .product-item-name + .item-options {
        margin-top: $cart-padding_m;

        @include max-screen($screen__s) {
            margin-top: $cart-padding_xs;
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent__base;
    }

    .cart-products-toolbar {
        + .cart {
            thead {
                tr {
                    th.col {
                        padding-bottom: 7px;
                        padding-top: 8px;
                    }
                }
            }
        }
    }
    .cart {
        + .cart-products-toolbar {
            margin-top: $indent__m;
        }
    }
}

.cart-container {
    @extend .abs-add-clearfix-desktop;

    .form-cart {
        @extend .abs-shopping-cart-items;
        @extend .abs-shopping-cart-items-desktop;
    }

    .widget {
        float: left;

        @include max-screen($screen__l) {
            float: none;
        }
    }

    .checkout-methods-items {
        @extend .abs-reset-list;
        margin-top: $indent__base;
        text-align: right;
        @include max-screen($screen__s) {
            text-align: center;
        }

        .action.primary {
            @include cart-checkout-button();
        }
        .action.multicheckout {
            color: $checkout-green;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }

        }

        .item {
            & + .item {
                margin-top: $indent__base;
            }
        }
    }
}


//  Discount Code
.cart-summary-divlink {
    .actions-toolbar {
        float: right;
        margin-left: 0 !important;
        .primary .action {
            margin-right: 0;
            @include cart-checkout-button-sm();
            &[disabled]{
                @include cart-checkout-button-disabled-sm();
            }
            &:last-child {
                @media screen and (max-width: $screen__m) {
                    margin-left: 0;
                    margin-bottom: 25px;
                }
            }
        }
    }
}


//  Products pager
.cart-products-toolbar {
    .toolbar-amount {
        margin: $indent__m 0 15px;
        padding: 0;
        text-align: center;
    }

    .toolbar-amount {
        line-height: 30px;
        margin: 0;
    }

    .pages {
        margin: 0 0 $indent__m;
        text-align: center;

        .items {
            > .item {
                border-bottom: 0;
            }
        }
    }

    .pages {
        float: right;
        margin: 0 0 1px;

        .item {
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.cart-products-toolbar-top {
    border-bottom: $border-width__base solid $border-color__base;
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
    display: inline-block;
    width: 100%;
    margin-left: 0px;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    @include cart-checkout-body-font();
    h2 {
        @include cart-checkout-h2-font();
    }
    .product-items {
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: flex-start;
        margin: 0;
        .product-item {
            border: 2px solid $checkout-light_grey;
            border-radius: 3px;
            padding: 25px;
            flex: 2 1 auto;
            max-width: 850px;
            min-width: 350px;
            align-self: stretch;
            &:nth-child(odd){
                margin: 0;
                @media only screen and (max-width: $screen__l){
                    margin: 20px 0 0 0;
                    width: 100%;
                    &:first-child(){
                        margin: 0;
                    }
                }
            }
            &:nth-child(even){
                margin: 0 0 0 20px;
                @media only screen and (max-width: $screen__l){
                    margin: 20px 0 0 0;
                    width: 100%;
                }
            }
            @include cart-checkout-crossSell-productTitle-font();
            .product-item-img {
                display: inline-block;
                position: relative;
                width: 35%;
                float: left;
                box-sizing: border-box;
                @media only screen and (max-width: $screen__s){
                    width: 100%;
                }
            }
            .product-item-info {
                float: right;
                display: inline-block;
                position: relative;
                width: 60%;
                text-align: left;
                @media only screen and (max-width: $screen__s){
                    width: 100%;
                    text-align: center;
                }
                .product-item-name {
                    margin: 0 0 16px 0;
                    @include cart-checkout-crossSell-productTitle-font();
                }
                .price-box .price {
                    @include cart-checkout-crossSell-price-font();
                }
                .description-item-wrapper {
                    margin: 0 0 20px 0;
                    @include cart-checkout-body-font();
                }
                .product-item-actions {
                    margin: 0;
                    display: block;
                    button {
                        @include cart-checkout-button-crossSell();
                        display: inline-block;
                    }
                }
            }
        }
    }
}




.cart-tax-info,
.cart .cart-tax-info {
    + .cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper,
.order-items.table-wrapper {
    .col.price,
    .col.qty,
    .col.subtotal,
    .col.msrp {
        text-align: right;
    }
}

// Empty Cart Page //
.empty-cart-page {
    .page-main {
        max-width: 72%;
        @include max-screen($screen__m) {
            max-width: 100%;
        }
        .column.main {
            min-height: 0;
            .actions {
                display: none;
            }
        }
    }
}


//
//  Utility Classes
//  ______________________________________________
.no-margin {
    margin: 0;
}
