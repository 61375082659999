/* Widget Start - Location - App / design / frontend / Scottsbase / default / SMG_HeroVideo / styles */
body {
    .hero-video-widget.product-info-container {
        .product-info.text-left {
            text-align: left;
            margin-left: 75px;
            margin-top: 150px;
            @media only screen and (max-width: $screen__l) {
                margin-left: 15px;
                margin-top: 275px;
            }
            @media only screen and (max-width: $screen__s) {
                margin-left: 15px;
                margin-top: 150px;
            }
        }
        .product-info.text-right {
            float: right;
            margin-right: 75px;
            margin-top: 150px;
            @media only screen and (max-width: $screen__l) {
                margin-right: 15px;
                margin-top: 275px;
            }
            @media only screen and (max-width: $screen__s) {
                margin-right: 15px;
                margin-top: 150px;
            }
        }
    }
}

body {
    section.hero-video-widget.product-info-container {
        display: inline-block;
        width: 100%;
        height: 600px;
        margin: 0 0 100px 0 !important;
        background-position: center;
        background-size: cover;
        @media only screen and (max-width: $screen__l) {
            margin: 0 !important;
            height: 350px;
        }
        @media only screen and (max-width: $screen__s) {
            height: 200px;
        }
        .product-info {
            font-size: 20px;
            width: 40%;
            @media only screen and (max-width: $screen__l) {
                width: 100%;
            }
            h2.video-headline {
                @media only screen and (max-width: $screen__l) {
                    display: none;
                }
            }
            p {
                @media only screen and (max-width: $screen__l) {
                    display: none;
                }
            }
            .video {
                @media only screen and (max-width: $screen__s) {
                    width: 100%;
                    display: inline-block;
                    position: relative;
                }
                .watch-video {
                    i {
                        height: 25px;
                        width: 25px;
                        line-height: 26px;
                        color: #ffffff;
                        font-size: 12px;
                        background: #000000;
                        border-radius: 50%;
                        text-align: center;
                        display: inline-block;
                        margin-right: 1%;
                        padding: 5px;
                        @media only screen and (max-width: $screen__s) {
                            padding: 2px;
                        }
                    }
                    span {
                        display: inline-block;
                        color: #ffffff;
                        font-size: 16px;
                        font-weight: 700;
                        font-family: "Hellix-Heavy";
                        letter-spacing: 0.5px;
                        text-decoration: underline;
                        padding-bottom: 3px;
                        position: relative;
                        top: -3px;
                        @media only screen and (max-width: $screen__l) {
                            top: 0;
                        }
                        p {
                            display: inline-block;
                        }
                    }
                    span.embed-code {
                        display: none !important;
                    }
                }
                /*
                .icon-play:before {
                    content: "\f04b";
                }
                */
            }
        }
        .heroVideo-video-overlay {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 8;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, .9);
            visibility: hidden;
            opacity: 0;
            transition: .2s visibility linear, .2s opacity linear;
        }
        .heroVideo-video-container {
            display: none;
            position: fixed;
            top: 50%;
            left: 50%;
            width: 100%;
            max-width: 1000px;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            z-index: 9;
            .video-frame {
                position: relative;
                padding-bottom: 56.25%; /* 16:9 */
                padding-top: 25px;
                height: 0;
            }
            .video-frame iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    .hero-video-mobileContent {
        @media only screen and (min-width: $screen__l) {
            display: none;
        }
        @media only screen and (max-width: $screen__l) {
            display: inline-block;
            position: relative;
            margin: 0 0 85px 0;
            h2 {
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
                width: 90%;
                font-size: 32px;
            }
            p {
                width: 90%;
            }
        }




    }
}

/* Styling relating to the Product Callout Video Widget */
body.heroVideo-video-open {
    .hero-video-widget.product-info-container {
        .heroVideo-video-overlay {
            visibility: visible;
            opacity: 1;
        }
        .heroVideo-video-container {
            display: block;
        }
    }
}
