/* Features List Widget Styling */
section.feature-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;
    h2 {
        width: 100%;
        text-align: center;
        margin-bottom: 75px;
        margin-top: 0;
        @include max-screen($screen__l) {
            margin-bottom: 0;
        }
    }
    h3 {
        color: #000000;
        margin-bottom: 15px;
    }
    div.item {
        margin-bottom: 50px;
        padding: 5px 5px;
        text-align: center;
        max-width: 300px;
        @media only screen and (max-width: $screen__m) {
            margin-bottom: 25px;
        }
        @media only screen and (max-width: $screen__s) {
            margin-bottom: 0px;
        }
        .image-container {
            height: 125px;
            width: 125px;
            background: $color-white;
            border-radius: 50%;
            color: #008065;
            box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.04);
            display: block;
            margin: 0 auto;
            img {
                display: inline-block;
                height: 65px;
                padding: 28px 0 0 0;
            }
        }
        p {
            margin-right: auto;
            margin-left: auto;
            line-height: 26px;
            color: #909090;
        }
    }
}
