//SCSS file for the SMG Icon List Widget

.page-wrapper .page-main .grid > {
    h2.list-icon-header {
        display: inline-block;
        position: relative;
        margin: 0;
        float: left;
        @media only screen and (max-width: $screen__l) {
            margin-bottom: 20px;
        }
    }
    ul.list-icon {
        list-style: none;
        display: inline-block;
        position: relative;
        padding: 0;
        float: right;
        margin-bottom: 0;
        li.list-icon-detail {
            display: inline-block;
            width: 42%;
            margin-right: 5%;
            text-align: right;
            @media only screen and (max-width: $screen__m) {
                width: 100%;
            }
            span.list-icon-detail-test {
                display: inline-block;
                margin-right: 5%;
              @media only screen and (max-width: $screen__m) {
                float: left;
              }
            }
            img {
                float: right;
                width: 25%;
                @media only screen and (max-width: $screen__l) {
                    width: 20%;
                }
                @media only screen and (max-width: $screen__m) {
                    float: right;
                    width: 12%;
                    margin-right: 20%;
                }
            }
        }
    }
}
