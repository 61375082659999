// Mixins


//  ---------------------------------------------
//  General Styling
//  ---------------------------------------------


@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}


@mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}


@mixin defaultwrap($center: true) {
  @if $center == false {
    margin: 0;
  }
  @else {
    margin: 0 auto;
  }

  max-width: 90em;
  width: 95%;
}


@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}


@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}


@mixin transition($x...){
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}


//  ---------------------------------------------
//  Typography
//  ---------------------------------------------

@mixin font-lato($size: false, $color: false, $weight: false, $lh: false, $ls: false) {
    font-family: $lato;
    @if $size { font-size: $size; }
    @if $color { color: $color; }
    @if $weight { font-weight: $weight; }
    @if $lh { line-height: $lh; }
    @if $ls { letter-spacing: $ls; }
}


@mixin font-open-sans($size: false, $color: false, $weight: false, $lh: false, $ls: false) {
    font-family: $opensans;
    @if $size { font-size: $size; }
    @if $color { color: $color; }
    @if $weight { font-weight: $weight; }
    @if $lh { line-height: $lh; }
    @if $ls { letter-spacing: $ls; }
}


@mixin roboto-regular($size: $font-size__base, $color: $color-black, $weight: false, $lh: false, $ls: false) {
  font-family: $font-family__quaternary;
  @if $size { font-size: $size; }
  @if $color { color: $color; }
  @if $weight { font-weight: $weight; }
  @if $lh { line-height: $lh; }
  @if $ls { letter-spacing: $ls; }
}


//Cart & Checkout - Body Font (Mobile)
@mixin cart-checkout-body-font-mobile($size: $font-size__s, $lh: 18px) {
  @if $size { font-size: $size; }
  @if $lh { line-height: $lh; }
}


//Cart & Checkout - Body Font
@mixin cart-checkout-body-font($size: $font-size__base, $color: $color-black, $weight: 400, $lh: 20px, $ls: false) {
    font-family: $font-family__quaternary;
    @if $size { font-size: $size; }
    @if $color { color: $color; }
    @if $weight { font-weight: $weight; }
    @if $lh { line-height: $lh; }
    @if $ls { letter-spacing: $ls; }
}


//Cart & Checkout - Cross Sell Product Title Font
@mixin cart-checkout-crossSell-productTitle-font() {
    @include cart-checkout-body-font($size: $font-size__m, $weight: 700);
}


//Cart & Checkout - Cross Sell Price Font
@mixin cart-checkout-crossSell-price-font() {
  @include cart-checkout-body-font($size: $font-size__s, $weight: 700, $ls: 1px);
}










