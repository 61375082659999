@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/light/opensans-300',
    $font-weight: 300,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/regular/opensans-400',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/semibold/opensans-600',
    $font-weight: 600,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/bold/opensans-700',
    $font-weight: 700,
    $font-style: normal
);

@include lib-font-face(
        $family-name: 'Open-Sans',
        $font-path: '../fonts/opensans/light/opensans-300',
        $font-weight: 300,
        $font-style: normal
);

@include lib-font-face(
        $family-name: 'Open-Sans-Cond',
        $font-path: '../fonts/open-sans-cond/open-sans-condensed-v14-latin-300',
        $font-weight: 400,
        $font-style: normal
);

@include lib-font-face(
        $family-name: 'Roboto',
        $font-path: '../fonts/roboto/roboto-v20-latin-regular',
        $font-weight: 400,
        $font-style: normal
);

@include lib-font-face(
        $family-name: 'Roboto-Bold',
        $font-path: '../fonts/roboto/roboto-v20-latin-700',
        $font-weight: 700,
        $font-style: normal
);

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}
