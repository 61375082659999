//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin              : $indent__base !default;
$checkout-wrapper__columns             : 6 !default; //Change to 6 for 50% width

$checkout-step-title__border           : $cart-checkout-h2-border;
$checkout-step-title__font-size        : 26px;
$checkout-step-title__font-weight      : $font-weight__light !default;
$checkout-step-title__padding          : 0 0 20px 0;

$checkout-step-title-mobile__font-size : 18px !default;

.checkout-index-index {
    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }
}

.checkout-container {
    @extend .abs-add-clearfix;
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);
}

.opc-wrapper {
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);
    @include cart-checkout-body-font();
    @include max-screen($screen__m) {
        @include cart-checkout-body-font-mobile();
    }
    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @include cart-checkout-h2-font();
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }
}

.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .logo {
        margin-left: 0;
    }
}

.checkout-onepage-success {
    @extend .abs-add-clearfix;

    .print {
        display: none;
    }
}



//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, 50%); //Width was $checkout-sidebar__columns "grid-cols"
        padding-right: $indent__l;
    }

    .checkout-onepage-success {
        .print {
            display: block;
            float: right;
            margin: 22px 0 0;
        }
    }
}
