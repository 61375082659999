//
//  Variables
//  _____________________________________________

$checkout-sidebar-shipping-information__padding                : 22px $indent__l !default;
$checkout-sidebar-shipping-information__line-height            : $checkout-billing-address-details__line-height !default;

$checkout-sidebar-shipping-information-edit-icon__color        : $minicart-icons-color !default;
$checkout-sidebar-shipping-information-edit-icon__content      : $icon-settings !default;
$checkout-sidebar-shipping-information-edit-icon__font-size    : 28px !default;
$checkout-sidebar-shipping-information-edit-icon__line-height  : 28px !default;
$checkout-sidebar-shipping-information-edit-icon__top          : 2px !default;
$checkout-sidebar-shipping-information-edit-icon__hover__color : $primary__color !default;

$checkout-sidebar-shipping-information__width                  :75%;
$checkout-sidebar-shipping-information-mobile__width           :100%;




//
//  Shipping Information
//  ---------------------------------------------

.opc-block-shipping-information {
    @extend .abs-add-box-sizing;
    @include lib-css(width, $checkout-sidebar-shipping-information__width);
    @include cart-checkout-body-font($size: $font-size__m, $lh: 21px);
    float: right;

    .shipping-information-title {
        @include cart-checkout-h2-font();
        position: relative;

        .action-edit {
            @extend .abs-action-button-as-link;
            @include lib-css(top, $checkout-sidebar-shipping-information-edit-icon__top);
            @include lib-icon-font(
                $checkout-sidebar-shipping-information-edit-icon__content,
                $_icon-font-size         : $checkout-sidebar-shipping-information-edit-icon__font-size,
                $_icon-font-line-height  : $checkout-sidebar-shipping-information-edit-icon__line-height,
                $_icon-font-text-hide    : true,
                $_icon-font-color        : $checkout-sidebar-shipping-information-edit-icon__color,
                $_icon-font-color-hover  : $checkout-sidebar-shipping-information-edit-icon__hover__color,
                $_icon-font-color-active : $checkout-sidebar-shipping-information-edit-icon__color
            );
            margin: 0;
            position: absolute;
            right: 0;
            &:before {
                content: 'Edit';
                @include cart-checkout-h2-font($size: $font-size__s, $ls:normal, $border: 0);
                @include max-screen($screen__m) {
                    @include cart-checkout-h2-font-mobile($size: $font-size__s); //needed to override mixin's media query
                }
            }   
        }
    }
    .shipping-information-content {
        .actions-toolbar {
            margin-left: 0;
        }
    }
    .ship-to,
    .ship-via {
        margin: 0 0 $indent__base;
    }
}



//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {

    .opc-block-shipping-information {
        @include lib-css(padding, $checkout-sidebar-shipping-information__padding);
        @include lib-css(font-size, $font-size__base);
        @include lib-css(width, $checkout-sidebar-shipping-information-mobile__width);
    }
}
